import { makeStyles } from '@admitkard/ccl';
import { HeaderLabel } from 'components/Home/HomeV2/Components/Sections/CommonComponents';
import React, { FunctionComponent, useState } from 'react';
const useStyles = makeStyles(
  () => ({
    root: {
      // marginLeft: '2rem',
      width: '100%',
      fontFamily: 'Poppins',
      // '@media screen and (min-width: 992px)': {
      //   marginLeft: '0rem',
      // },
    },
    carousel: {
      marginLeft: '1rem',
      '@media screen and (min-width: 992px)': {
        marginLeft: '0rem',
        height: '30.5rem',
      },
      height: '25.5rem',
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
    },
    handleContainerLeft: {
      display: 'none',
      '@media screen and (min-width: 1200px)': {
        display: 'flex',
        justifyContent: 'left',
        overflow: 'hidden',
        height: '100%',
        position: 'absolute',
        left: 0,
        background: 'linear-gradient(to right, rgba(256,256,256,0), rgba(256,256,256,0.5))',
      },
    },
    handleContainerRight: {
      display: 'none',
      '@media screen and (min-width: 1200px)': {
        display: 'flex',
        justifyContent: 'right',
        overflow: 'hidden',
        height: '100%',
        position: 'absolute',
        right: 0,
        background: 'linear-gradient(to right, rgba(256,256,256,0), rgba(256,256,256,0.5))',
      },
    },
    handle: {
      border: 'none',
      borderRadius: '1rem',
      flexGrow: 0,
      flexShrink: 0,
      backgroundColor: 'transparent',
      zIndex: 10,
      width: '5rem',
      fontSize: '5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#483287',
      lineHeight: 0,
      transition: 'background-color 150ms ease-in-out',
      '&:hover &:focus': {
        backgroundColor: 'rgba(255, 255, 255, .5)',
      },
    },
    handleBox: {
      height: '4rem',
      display: 'flex',
      placeContent: 'center',
      aspectRatio: '1/1',
      borderRadius: '1.25rem',
      border: 'none',
      fontSize: '3rem',
      cursor:'pointer',
      '&:disabled':{
        cursor: 'initial',
      }
    },
    leftHandle: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: '-0.5rem',
    },
    rightHandle: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      marginRight: '-0.5rem',
    },
    carouselContent: {
      overflowX: 'auto',
      marginRight: '1rem',
      borderRadius: '1.75rem',
      '@media screen and (min-width: 1200px)': {
        marginRight: '5rem',
        marginLeft:'5rem',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    cardContainer: {
      display: 'flex',
      gap: '1rem',
      width: 'max-content',
    },
    card: {
      position: 'relative',
      width: '16.66rem',
      height: '25rem',
      padding: '0.25rem',
      display: 'flex',
      borderRadius: '1.75rem',
      '-webkit-border-radius': '1.75rem',
      overflow: 'hidden',
      '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
      '&:last-child': {
        width: '.825rem',
      },
      '@media screen and (min-width: 992px)': {
        width: '20rem',
        height: '30rem',
      },
      '@media screen and (min-width: 400px)': {
        '&:last-child': {
          display: 'none',
        },
      },
    },
    cardBackground: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      right: 0,
      bottom: 0,
      minWidth: '100%',
      minHeight: '100%',
      '& video': {
        objectFit: 'cover',
        width: 'inherit',
      },
    },
    playButton: {
      position: 'absolute',
      width: 'inherit',
      height: 'inherit',
      display: 'grid',
      placeItems: 'center',
    },
    button: {
      color: '#F7EFFF',
      boxSizing: 'border-box',
      border: 'none',
      display: 'grid',
      placeItems: 'center',
      width: '3.2rem',
      height: '3.2rem',
      borderRadius: '50%',
      transition: 'all 0.3s ease',
      '& svg': {
        marginLeft: '0.1rem',
      },
    },
    infoContainer: {
      zIndex: 10,
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '1.5rem',
      backgroundColor: '#FFFFFF',
      alignSelf: 'end',
      opacity: '1',
      transition: 'all 0.5s linear',
    },
    testimony: {
      fontSize: '0.8rem',
      lineHeight: '1.2rem',
      color: '#483287',
      margin: '0.9rem',
      maxHeight: '4.65rem',
      '-webkit-line-clamp': '4',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '@media screen and (min-width: 992px)': {
        fontSize: '0.95rem',
        lineHeight: '1.4rem',
        margin: '1rem',
        maxHeight: '5.65rem',
      },
    },
    divider: {
      width: '100%',
      height: '0rem',
      border: '1px solid #A259FF',
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '3rem',
      '@media screen and (min-width: 992px)': {
        height: '4rem',
      },
    },
    name: {
      margin: '1.5rem',
      color: '#483287',
      fontSize: '0.75rem',
      '@media screen and (min-width: 992px)': {
        fontSize: '0.875rem',
      },
    },
    universityLogo: {
      height: '2rem',
      marginRight: '0.5rem'
    },
  }),
  { name: 'SecondFold' }
);
const SecondFold: FunctionComponent<any> = () => {

  const [disableLeft, setDisableLeft] = useState(true);
  const [disableRight, setDisableRight] = useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (target.scrollLeft === 0) {
      setDisableLeft(true);
    } else {
      setDisableLeft(false);
    }
    if (target.scrollWidth - target.clientWidth === target.scrollLeft) {
      setDisableRight(true);
    } else {
      setDisableRight(false);
    }
  };

  function scrollIntoViewLeft(): void {
    const element = document.getElementById('carousel');
    element?.scroll(element.offsetWidth + 16, 0);
  }
  function scrollIntoViewRight(): void {
    const element = document.getElementById('carousel');
    element?.scroll(element.offsetWidth * -1 - 16, 0);
  }
  const classes = useStyles();
  const testimonials = [
    {
      studentName: 'Tarushi Gupta',
      testimonial: 'I would highly recommend Admitkard to any study abroad aspirant.',
      universityName: 'University of Lagos',
      universityLogo: 'https://assets.admitkard.com/videos/university-logos/carnegie-mellon-university.webp',
      video: 'Tarushi+Gupta+_P_F_V2',
      thumbnail: 'https://assets.admitkard.com/videos/thumbnails/Tarushi+Gupta+TN.webp',
    },
    {
      studentName: 'Siddarth Warrier',
      testimonial: 'Admitkard has a great team and a solid work ethic.',
      universityName: 'University of Lagos',
      universityLogo: 'https://assets.admitkard.com/videos/university-logos/university-of-toronto.webp',
      video: 'Siddharth+Warrier_P_F_V2',
      thumbnail: 'https://assets.admitkard.com/videos/thumbnails/Siddharth+Warrier+TN.webp',
    },
    {
      studentName: 'Subarna Chakraborty',
      testimonial: 'Have it not been for Admitkard I would not have reached my goals',
      universityName: 'University of Lagos',
      universityLogo: 'https://assets.admitkard.com/videos/university-logos/imperial-college-of-london.webp',
      video: 'Subarna+Chakraborty_P_F_V2',
      thumbnail: 'https://assets.admitkard.com/videos/thumbnails/Subarna+Chakraborty+TN.webp',
    },
    {
      studentName: 'Ritankar Saha Roy',
      testimonial: 'Admitkard is really focused on student success.',
      universityName: 'University of Lagos',
      universityLogo: 'https://assets.admitkard.com/videos/university-logos/coventry-university.webp',
      video: 'Rhitankar+Saha+Roy+_P_F_V2',
      thumbnail: 'https://assets.admitkard.com/videos/thumbnails/Rhitankar+Saha+Roy+_TN.webp',
    },
  ];
  return (
    <>
      {/* <div style={{ width: '100vw', background: '#FCFAFF', position: 'absolute' }} /> */}
      <div className={classes.root}>
        <HeaderLabel label="hear from our students" />
        <div className={classes.carousel}>
          <div className={classes.handleContainerLeft}>
            <div className={`${classes.handle} ${classes.leftHandle}`}>
              <button className={classes.handleBox} onClick={scrollIntoViewRight} disabled={disableLeft}>
                <div>&#8249;</div>
              </button>
            </div>
          </div>
          <div className={classes.carouselContent} id="carousel" onScroll={handleScroll}>
            <div id='testimonials' className={classes.cardContainer}>
              <Cards classes={classes} testimonials={testimonials} />

              <div className={classes.card} />
            </div>
          </div>
          <div className={classes.handleContainerRight}>
            <div className={`${classes.handle} ${classes.rightHandle}`}>
              <button className={classes.handleBox} onClick={scrollIntoViewLeft} disabled={disableRight}>
                <div>&#8250;</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface Testimonial {
  studentName: string;
  testimonial: string;
  universityName: string;
  universityLogo: string;
  video: string;
  thumbnail?: string;
}
interface CardProps {
  classes: any;
  testimonials: Testimonial[];
}

const Cards: FunctionComponent<CardProps> = (props) => {
  const { classes, testimonials } = props;
  function onMouseOver(videoId: string, buttonId: string, infoId: string, dividerId: string): void {
    const video = document.getElementById(videoId) as HTMLVideoElement;
    const btn = document.getElementById(buttonId) as HTMLButtonElement;
    const info = document.getElementById(infoId) as HTMLDivElement;
    const divider = document.getElementById(dividerId) as HTMLDivElement;
    if (video.paused) {
      video.play();
      btn.style.display = 'none';
      info.style.display = 'none';
      divider.style.display = 'none';
      // btn.animate([{ display: 'unset'}, {display: 'none'}], { duration: 200, fill: 'forwards' });
      // info.animate([{ display: 'unset'}, {display: 'none'}], { duration: 200, fill: 'forwards' });
    }
  }
  function onMouseOut(videoId: string, buttonId: string, infoId: string, dividerId: string): void {
    const video = document.getElementById(videoId) as HTMLVideoElement;
    const btn = document.getElementById(buttonId) as HTMLButtonElement;
    const info = document.getElementById(infoId) as HTMLDivElement;
    const divider = document.getElementById(dividerId) as HTMLDivElement;

    if (video.played) {
      video.pause();
      btn.style.display = 'unset';
      info.style.display = 'unset';
      divider.style.display = 'unset';
      // btn.animate([{ opacity: 0 }, { opacity: 1 }], { duration: 200, fill: 'forwards' });
      // info.animate([{ opacity: 0 }, { opacity: 1 }], { duration: 200, fill: 'forwards' });
    }
  }
  function onHandleClick(videoId: string, buttonId: string, infoId: string, dividerId: string): void {
    const video = document.getElementById(videoId) as HTMLVideoElement;
    const btn = document.getElementById(buttonId) as HTMLButtonElement;
    const info = document.getElementById(infoId) as HTMLDivElement;
    const divider = document.getElementById(dividerId) as HTMLDivElement;
    if (video.paused) {
      video.play();
      btn.style.display = 'none';
      info.style.display = 'none';
      divider.style.display = 'none';
      // btn.animate([{ display: 'unset'}, {display: 'none'}], { duration: 200, fill: 'forwards' });
      // info.animate([{ display: 'unset'}, {display: 'none'}], { duration: 200, fill: 'forwards' });
    }
    if (video.played) {
      video.pause();
      btn.style.display = 'unset';
      info.style.display = 'unset';
      divider.style.display = 'unset';
      // btn.animate([{ opacity: 0 }, { opacity: 1 }], { duration: 200, fill: 'forwards' });
      // info.animate([{ opacity: 0 }, { opacity: 1 }], { duration: 200, fill: 'forwards' });
    }
  }
  return (
    <>
      {testimonials.map((testimonial: Testimonial, index: number) => {
        return (
          <div key={index} className={classes.card}>
            <div className={classes.cardBackground}>
              <video id={`myVideo${index}`} style={{ width: '102%' }} poster={testimonial.thumbnail} >
                <source src={`https://assets.admitkard.com/videos/${testimonial.video}.webm`} type="video/webm" />
                <source src={`https://assets.admitkard.com/videos/${testimonial.video}.mp4`} type="video/mp4" />
              </video>
            </div>
            <div
              className={classes.playButton}
              onMouseOver={() => onMouseOver(`myVideo${index}`, `myBtn${index}`, `info${index}`, `divider${index}`)}
              onMouseOut={() => onMouseOut(`myVideo${index}`, `myBtn${index}`, `info${index}`, `divider${index}`)}
              onTouchStart={() => onHandleClick(`myVideo${index}`, `myBtn${index}`, `info${index}`, `divider${index}`)}
            >
              <button id={`myBtn${index}`} className={classes.button}>
                <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.6966 9.2795C15.0921 10.086 15.0972 11.1005 13.6966 12.0123L2.8741 19.5816C1.51427 20.3342 0.590714 19.8898 0.493766 18.2614L0.447843 2.41915C0.417228 0.919166 1.60867 0.49537 2.74398 1.21454L13.6966 9.2795Z"
                    fill="#A259FF"
                  />
                </svg>
              </button>
            </div>
            <div className={classes.infoContainer}>
              <p id={`info${index}`} className={classes.testimony}>
                {testimonial.testimonial}{' '}
              </p>
              <div id={`divider${index}`} className={classes.divider} />
              <div className={classes.info}>
                <p className={classes.name}>{testimonial.studentName}</p>
                <img className={classes.universityLogo} src={testimonial.universityLogo} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default SecondFold;
